import React from "react";
import { useParams } from "react-router-dom";
//
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Table from "components/enhancedTable";
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import { green, blue, lightGreen, lightBlue, grey } from '@material-ui/core/colors';
import GridOnIcon from '@material-ui/icons/GridOnOutlined';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FolderSharedIcon from '@material-ui/icons/FolderShared';

import ButtonMenu from "components/buttonMenu";
import CancelCooperativeContractRequestsDialog from "containers/cancelCooperativeContractRequestsDialog";
import ShareCooperativeContractRequestsDialog from "containers/shareCooperativeContractRequestsDialog";
import UnshareCooperativeContractRequestsDialog from "containers/unshareCooperativeContractRequestsDialog";
import ProtectCooperativeContractRequestsDialog from "containers/protectCooperativeContractRequestsDialog";
import UnprotectCooperativeContractRequestsDialog from "containers/unprotectCooperativeContractRequestsDialog";

const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

const GET_ALL_COOPERATIVE_CONTRACT_REQUESTS = gql`
  query GetAllContractRequests($cooperative: String!) {
    contractRequests(cooperative: $cooperative) {
      id
      title
      status      
      sheetFile {
        url
      }  
      createdAt
      sharingStatus
      sharedStatus
      sharedContractRequests {
        id
        status
        permissions
        groupType
        groupName
        userType
        userName
        sharedStatus
        sharedFolders {
          url
        }
      }      
    }
  }
`;

const etl = data => {
  return data.contractRequests.map(
    (contractRequest, index) => [
      //new Date(attendance.referenceTime).toLocaleDateString(),
      contractRequest.title,
      //contractRequest.status,      
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          <RenderStatus status={contractRequest.status} />
        </Grid>
      </Grid>,
      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          <RenderSharingStatus sharingStatus={contractRequest.sharingStatus} sharedStatus={contractRequest.sharedStatus} />
        </Grid>
      </Grid>,
      new Date(contractRequest.createdAt).toLocaleDateString(),
      /*<ButtonGroup variant="text" size="small" aria-label="small contained button group">
        {contractRequest.sheetFile && 
          <Tooltip title={'File assunzioni'} placement="top"><Button 
            startIcon={<GridOnIcon></GridOnIcon>}>
            <Link href={contractRequest.sheetFile.url} rel="noopener noreferrer" target="_blank">File assunzioni</Link>
          </Button></Tooltip>}

        {contractRequest.sheetFile 
          //&& (!contractRequest.sharingStatus || !(contractRequest.sharingStatus.toLowerCase() === 'shared')) 
          && <ShareCooperativeContractRequestsDialog contractRequests={contractRequest}/>}
        {contractRequest.sheetFile 
          //&& (contractRequest.sharingStatus && contractRequest.sharingStatus.toLowerCase() === 'shared') 
          && <UnshareCooperativeContractRequestsDialog contractRequests={contractRequest}/>}
        {contractRequest.sheetFile && <CancelCooperativeContractRequestsDialog contractRequests={contractRequest}/>}
      </ButtonGroup>*/
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          {true && contractRequest.sheetFile &&
            <Tooltip title={'File assunzioni'} placement="top">
              <Link href={contractRequest.sheetFile.url} rel="noopener noreferrer" target="_blank">
                <IconButton
                  size="small"
                  //color="primary"      
                  aria-label="add">
                  <GridOnIcon />
                </IconButton>
              </Link>
            </Tooltip>}
        </Grid>
        <Grid item xs>
          {true && contractRequest.sharingStatus &&
            <ButtonMenu buttonTitle={'Cartelle condivise'} icon={FolderSharedIcon} items={ sharedFolderLinks({sharedContractRequests: contractRequest.sharedContractRequests}) } />}
        </Grid>
      </Grid>,
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          {contractRequest.sheetFile
            //&& (!contractRequest.sharingStatus || !(contractRequest.sharingStatus.toLowerCase() === 'shared')) 
            && <ShareCooperativeContractRequestsDialog contractRequests={contractRequest} />}
        </Grid>
        <Grid item xs>
          {contractRequest.sheetFile
            && contractRequest.sharingStatus
            //&& (contractRequest.sharingStatus && contractRequest.sharingStatus.toLowerCase() === 'shared') 
            && <UnshareCooperativeContractRequestsDialog contractRequests={contractRequest} />}
        </Grid>
        <Grid item xs>
          {contractRequest.sheetFile
            //&& (!contractRequest.sharingStatus || !(contractRequest.sharingStatus.toLowerCase() === 'shared')) 
            && <ProtectCooperativeContractRequestsDialog contractRequests={contractRequest} />}
        </Grid>  
        <Grid item xs>
          {contractRequest.sheetFile
            //&& (!contractRequest.sharingStatus || !(contractRequest.sharingStatus.toLowerCase() === 'shared')) 
            && <UnprotectCooperativeContractRequestsDialog contractRequests={contractRequest} />}
        </Grid>              
        <Grid item xs>
          {contractRequest.sheetFile && <CancelCooperativeContractRequestsDialog contractRequests={contractRequest} />}
        </Grid>
      </Grid>
    ]
  );
};

export default function () {
  const { id } = useParams();

  const {
    data: cooperativeNameData,
    loading: cooperativeNameLoading,
    error: cooperativeNameError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const { data, loading, error } = useQuery(GET_ALL_COOPERATIVE_CONTRACT_REQUESTS, {
    variables: {
      cooperative:
        cooperativeNameData && cooperativeNameData.cooperative.businessName
    },
    pollInterval: 1000
  });

  if (loading || cooperativeNameLoading) return <p>Caricamento...</p>;
  if (!data && (error || cooperativeNameError)) return <p>ERROR: {error.message}</p>;

  return <Table headers={["Titolo", "Stato", "Condivisione", "Creato il", "Documenti", "Azioni"]} rows={etl(data)} />;
}

const RenderStatus = ({ status }) => {
  if (status === 'PREPARED') return (<Tooltip title={status} placement="top"><CheckCircleOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  if (status && status.endsWith('ING')) return (<Tooltip title={status} placement="top"><CircularProgress disableShrink size={20} /></Tooltip>)  
  if (status === 'ERROR') return (<Tooltip title={status} placement="top"><ErrorOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  return status;
}

const RenderSharingStatus = ({ sharingStatus, sharedStatus }) => {
  if (sharingStatus === 'SHARED' && sharedStatus === 'COMPLETED') return (<Tooltip title={sharedStatus} placement="top"><CloudDoneIcon style={{ color: grey[500] }} /></Tooltip>)
  if (sharingStatus === 'SHARED') return (<Tooltip title={sharingStatus} placement="top"><CloudIcon style={{ color: grey[500] }} /></Tooltip>)
  if (sharingStatus && sharingStatus.endsWith('ING')) return (<Tooltip title={sharingStatus} placement="top"><CircularProgress disableShrink size={20} /></Tooltip>)
  if (sharingStatus === 'ERROR') return (<Tooltip title={sharingStatus} placement="top"><ErrorOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  return sharingStatus;
}

const sharedFolderLinks = ({ sharedContractRequests }) => {  
  if (!sharedContractRequests) return [];
  return [...new Map( sharedContractRequests.map(item => [item.groupName, {
    title: item.groupName,
    value: item.sharedFolders.url
  }]) ).values()];
}
