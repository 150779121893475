import React from "react";
import { useLocation, useParams } from "react-router-dom";
import BarNavigator from "components/barNavigator";

export default function(props) {
  const { id } = useParams();
  const { pathname } = useLocation();

  const categories = {
    tabs: [
      /*{
        path: `/cooperatives/${id}`,
        text: "Generale"
      }*/,
      {
        path: `/cooperatives/${id}/workers`,
        text: "Lavoratori"
      },
      {
        path: `/cooperatives/${id}/contractRequests`,
        text: "Assunzioni"
      },             
      {
        path: `/cooperatives/${id}/contracts`,
        text: "Contratti"
      },
      {
        path: `/cooperatives/${id}/jobDescriptions`,
        text: "Mansionari"
      },      
      {
        path: `/cooperatives/${id}/attendances`,
        text: "Presenze"
      },
      {
        path: `/cooperatives/${id}/salaries`,
        text: "Stipendi"
      },
      {
        path: `/cooperatives/${id}/payPackets`,
        text: "Buste Paga"
      },      
      /*{
        path: `/cooperatives/${id}/invoices`,
        text: "Fatture"
      },
      {
        path: `/cooperatives/${id}/receipts`,
        text: "Incassi"
      }*/
      ,
      {
        path: `/cooperatives/${id}/uniqueEmployeeCertifications`,
        text: "CUD"
      }      
    ],
    activeLocation: pathname
  };

  return <BarNavigator categories={categories} {...props} />;
}
