import React, { Fragment } from "react";
import CooperativesDetailContractsTable from "containers/cooperativesDetailContractsTable";
import CreateCooperativeContractDialog from "containers/createCooperativeContractDialog";
export default function() {
  return (
    <Fragment>
      <CooperativesDetailContractsTable />
      <CreateCooperativeContractDialog />
    </Fragment>
  );
}
