import React from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useStyles } from "./styles";
import { Controller } from 'react-hook-form';

export default function({ id, type, name, inputRef, label, error, options, control, defaultValue, ...rest }) {

  const classes = useStyles();

  return (
    <FormControl {...rest} fullWidth className={classes.formControl}>
      {false && <InputLabel id={`${id}-label`} htmlFor={id} shrink={true}>
        {label}
      </InputLabel>}

      { (type === 'text' || type === 'date') &&
      /*<Input
        id={id}
        type={type}
        name={name}
        inputRef={inputRef}
        error={!!error}
      />*/
      <Controller 
        control={control} 
        as={<TextField 
          label={label}                
        />} 
        name={name} 
        defaultValue={defaultValue || ''}
        />      
      }

      {(type === 'select') && 
      <InputLabel shrink={true}>
        {label}
      </InputLabel>}
      { (type === 'select') &&      
      <Controller 
        control={control}        
        as={<Select
        >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))} 
        </Select>}
        name={name}
      />       
      }

      {(type === 'multi-select') && 
      <InputLabel shrink={true}>
        {label}
      </InputLabel>}
      { (type === 'multi-select') &&      
      <Controller 
        control={control}               
        as={<Select  
          multiple  
          input={<Input />}          
        >         
        {options.map(option => (         
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))} 
        </Select>}
        //defaultValue={[]}
        defaultValue={defaultValue || []}
        name={name}
      /> }           

      { (type === 'switch') &&
      <Controller
        control={control}
        as={<FormControlLabel
          value={true}
          control={<Switch 
            ref={inputRef}
          />}
          label={label}
          labelPlacement="end"
        />}
        type="checkbox"
        name={name}
        //defaultValue={false}
        defaultValue={defaultValue || false}
      />         
      }

      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
}
