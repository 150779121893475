import React, { Fragment } from "react";
import CooperativesDetailContractRequestsTable from "containers/cooperativesDetailContractRequestsTable";
import CreateCooperativeContractRequestsDialog from "containers/createCooperativeContractRequestsDialog";
export default function() {
  return (
    <Fragment>
      <CooperativesDetailContractRequestsTable />
      <CreateCooperativeContractRequestsDialog />
    </Fragment>
  );
}
