import React, { Fragment } from "react";
import CooperativesDetailAttendancesTable from "containers/cooperativesDetailAttendancesTable";
import CreateCooperativeAttendanceDialog from "containers/createCooperativeAttendanceDialog";
export default function() {
  return (
    <Fragment>
      <CooperativesDetailAttendancesTable />
      <CreateCooperativeAttendanceDialog />
    </Fragment>
  );
}
