import React from "react";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import { useStyles } from "./styles";

export default function({ text, onClick }) {
  const classes = useStyles();

  return (
    <Button
      style={{ textTransform: "none" }}
      color="inherit"
      className={classes.button}
      onClick={onClick}
    >
      <ArrowBackIcon />
      {text}
    </Button>
  );
}
