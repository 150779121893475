import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  secondaryBar: {
    zIndex: 0,
    maxWidth: "100vw"
  }
}));

export { useStyles };
