import React, { useEffect } from "react";
import { BrowserRouter as ReactRouter, Switch, Route } from "react-router-dom";
// Templates
import AppTemplate from "templates/app";
import CooperativesTemplate from "templates/cooperatives";
import CooperativesDetailTemplate from "templates/cooperativesDetail";
import CustomersTemplate from "templates/customers";
import LoadingTemplate from "templates/loading";
import AppContentTemplate from "templates/appContent";
// Pages
import IndexPage from "pages";
import CooperativesPage from "pages/cooperatives";
import CooperativeCreatePage from "pages/cooperatives/create";
import CooperativesDetailPage from "pages/cooperatives/detail";
import CooperativesWorkersDetailPage from "pages/cooperatives/detail/workers";
import CooperativesContractRequestsDetailPage from "pages/cooperatives/detail/contractRequests";
import CooperativesContractsDetailPage from "pages/cooperatives/detail/contracts";
import CooperativesJobDescriptionsDetailPage from "pages/cooperatives/detail/jobDescriptions";
import CooperativesAttendancesDetailPage from "pages/cooperatives/detail/attendances";
import CooperativesSalariesDetailPage from "pages/cooperatives/detail/salaries";
import CooperativesPayPacketsDetailPage from "pages/cooperatives/detail/payPackets";
import CooperativesUniqueEmployeeCertificationsDetailPage from "pages/cooperatives/detail/uniqueEmployeeCertifications";
import CooperativesInvoicesDetailPage from "pages/cooperatives/detail/invoices";
import CooperativesReceiptsDetailPage from "pages/cooperatives/detail/receipts";
import CustomersPage from "pages/customers";
// Graphql
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";

const CHECK_AUTH = gql`
  query CheckAuth {
    isAuthenticated @client
    waitingForAuthentication @client
  }
`;

const LOGIN_WITH_REDIRECT = gql`
  mutation loginWithRedirect($path: String!) {
    loginWithRedirect(path: $path) @client
  }
`;

const Router = () => {
  const {
    loading,
    error,
    data: { isAuthenticated, waitingForAuthentication }
  } = useQuery(CHECK_AUTH);

  const [loginWithRedirect] = useMutation(LOGIN_WITH_REDIRECT, {
    variables: { path: window.location.pathname }
  });

  useEffect(
    () => {
      if (waitingForAuthentication || isAuthenticated) {
        return;
      }     
      loginWithRedirect();
    },
    [loading, waitingForAuthentication, isAuthenticated]
  );

  if (waitingForAuthentication || loading) return <LoadingTemplate />;

  return (
    <ReactRouter>
      <Switch>
        <Route path="/" exact component={IndexPage} />

        <AppTemplate>
          <Switch>
            <RouteTemplate
              path="/cooperatives"
              exact
              component={CooperativesTemplate}
            >
              <Route path="/cooperatives" exact component={CooperativesPage} />
            </RouteTemplate>

            <RouteTemplate
              path="/cooperatives/archived"
              exact
              component={CooperativesTemplate}
            >
              <Route path="/cooperatives/:group" exact component={CooperativesPage} />
            </RouteTemplate>

            <RouteTemplate
              path="/cooperatives/create"
              exact
              component={AppContentTemplate}
            >
              <Route
                path="/cooperatives/create"
                exact
                component={CooperativeCreatePage}
              />
            </RouteTemplate>

            <RouteTemplate
              path="/cooperatives/:id"
              component={CooperativesDetailTemplate}
            >
              <Route
                path="/cooperatives/:id"
                exact
                component={CooperativesDetailPage}
              />

              <Route
                path="/cooperatives/:id/workers"
                exact
                component={CooperativesWorkersDetailPage}
              />

              <Route
                path="/cooperatives/:id/contractRequests"
                exact
                component={CooperativesContractRequestsDetailPage}
              />

              <Route
                path="/cooperatives/:id/contracts"
                exact
                component={CooperativesContractsDetailPage}
              /> 
              <Route
                path="/cooperatives/:id/jobDescriptions"
                exact
                component={CooperativesJobDescriptionsDetailPage}
              />              
              <Route
                path="/cooperatives/:id/attendances"
                exact
                component={CooperativesAttendancesDetailPage}
              />
              <Route
                path="/cooperatives/:id/salaries"
                exact
                component={CooperativesSalariesDetailPage}
              />
              <Route
                path="/cooperatives/:id/payPackets"
                exact
                component={CooperativesPayPacketsDetailPage}
              />
              <Route
                path="/cooperatives/:id/invoices"
                exact
                component={CooperativesInvoicesDetailPage}
              />
              <Route
                path="/cooperatives/:id/receipts"
                exact
                component={CooperativesReceiptsDetailPage}
              /> 
              <Route
                path="/cooperatives/:id/uniqueEmployeeCertifications"
                exact
                component={CooperativesUniqueEmployeeCertificationsDetailPage}
              />                           
            </RouteTemplate>

            <RouteTemplate
              path="/customers"
              exact
              component={CustomersTemplate}
            >
              <Route path="/customers" exact component={CustomersPage} />
            </RouteTemplate> 
                       
          </Switch>
        </AppTemplate>
      </Switch>
    </ReactRouter>
  );
};

const RouteTemplate = ({ component: Component, children, ...rest }) => {
  return (
    <Route {...rest} render={props => <Component>{children}</Component>} />
  );
};

export default Router;
