import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import { useStyles } from "./styles";
import Typography from "@material-ui/core/Typography";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

export default function({ categories, actions, ...props }) {
  const classes = useStyles();

  return (
    <Drawer variant="permanent" {...props}>
      <List>
        <ListItem className={clsx(classes.firebase)}>
          <Typography component="h3" variant="h5">ConsorziMe <Typography component="h7" > ({process.env.REACT_APP_VERSION})</Typography></Typography>          
        </ListItem>
        <Divider className={classes.divider} />
        {categories.map(({ text, path, active }, index) => (
          <React.Fragment key={index}>
            <ListItem className={classes.categoryHeader} />
            {false && <Link to={path} style={{ textDecoration: "none" }}>
              <ListItem
                className={clsx(classes.item, active && classes.itemActiveItem)}
              >
                <ListItemIcon className={classes.itemIcon}>
                  <Icon />
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary
                  }}
                >
                  {text}
                </ListItemText>
              </ListItem>
            </Link>}
            <ListItem
              className={clsx(classes.item, active && classes.itemActiveItem)}
              {...{ to: path }}
              component={Link}
              button={true}                
            >
              <ListItemIcon className={classes.itemIcon}>
                <Icon />
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.itemPrimary
                }}
              >
                {text}
              </ListItemText>
            </ListItem>
          </React.Fragment>
        ))}
        <Divider className={classes.divider} />
        <React.Fragment key='9999999999'>
          <ListItem className={classes.categoryHeader} />
          <ListItem button component="a" className={clsx(classes.item)} href="https://consorzime.atlassian.net" target="_blank" rel="noopener noreferrer">
            <ListItemIcon className={classes.itemIcon}><ContactSupportIcon /></ListItemIcon>
            <ListItemText
              primary='Supporto'
              classes={{
                primary: classes.itemPrimary
              }}
            />
          </ListItem>
        </React.Fragment>     
        <Divider className={classes.divider} />
        {actions.map(({ text, action, icon }, index) => (
          <React.Fragment key={index}>
            <ListItem className={classes.categoryHeader} />
            <ListItem button onClick={action} className={clsx(classes.item)}>
              <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
              <ListItemText
                primary={text}
                classes={{
                  primary: classes.itemPrimary
                }}
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}
