import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import DialogForm from "components/buttonDialogForm";
import FormField from "components/formField";
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

export const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

export const CANCEL_CONTRACT = gql`
  mutation CancelContractBundle($input: CancelContractBundleInput!) {
    cancelContractBundle(
      input: $input
    ) {
      code
      success
      message
    }
  }  
`;

export default function ({ contract }) {
  const { id } = useParams();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const [
    cancelContractBundle,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(CANCEL_CONTRACT);

  const { register, handleSubmit, formState, errors } = useForm({
    //mode: "onChange"
  });

  const onSubmit = data => {
    //const { code, agreementDate, startDate } = data;
    const { } = data;

    const variables = {
      input: {
        //cooperative: queryData.cooperative.businessName
        id: contract.id
      }
    };

    cancelContractBundle({
      variables
    });
  };

  if (queryError) return <p>ERROR: {queryError.message}</p>;

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={mutationLoading}
        message={"Annullamento dei contratti  avviata con successo."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Si è verificato un errore nell'annullamento dei contratti "}
      />
      <DialogForm
        id="cancel-contract"
        title={
          queryData &&
          `Annullamento Contratti ${queryData.cooperative.businessName}`
        }
        subTitle={queryData && `La seguente procedura avvierà l'annullamento dei contratti per la società ${queryData.cooperative.businessName}, partendo dai contratti ${contract.title}.`}
        buttonTitle='Annulla contratti'
        formState={formState}
        icon={DeleteIcon}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* <FormField
            id="code"
            name="code"
            label="Codice"
            type="text"
            inputRef={register({ required: "Inserisci il codice." })}
            error={errors.password}
          />
          <FormField
            id="stip"
            name="agreementDate"
            label="Data stipula"
            type="date"
            inputRef={register({ required: "Inserisci data di stipula." })}
            error={errors.password}
          />
          <FormField
            id="start"
            name="startDate"
            label="Data inizio"
            type="date"
            inputRef={register({ required: "Inserisci data di inizio." })}
            error={errors.password}
          /> */}
      </DialogForm>
    </Fragment>
  );
}
