import React from "react";
import { useLocation } from "react-router-dom";
import BarNavigator from "components/barNavigator";

export default function(props) {
  const { pathname } = useLocation();

  const categories = {
    tabs: [
      {
        path: `/customers`,
        text: "Tutte le committenti"
      }
    ],
    activeLocation: pathname
  };

  return <BarNavigator categories={categories} {...props} />;
}
