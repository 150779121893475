import React from "react";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { useStyles } from "./styles";

export default function({ onClick }) {
  const classes = useStyles();

  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={onClick}
      className={classes.button}
    >
      <MenuIcon />
    </IconButton>
  );
}
