import React, { Fragment } from "react";
import SecondaryBar from "components/secondaryBar";
import { useStyles } from "./styles";

export default function({ children, title, secondaryNavigator }) {
  const classes = useStyles();

  return (
    <Fragment>
      {title && <SecondaryBar title={title} />}
      {secondaryNavigator}
      <main className={classes.main}>{children}</main>
    </Fragment>
  );
}
