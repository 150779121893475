import React from "react";
import { useParams } from "react-router-dom";
//
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import AppContent from "templates/appContent";
import CooperativesDetailNavigator from "containers/cooperativesDetailNavigator";

export const GET_COOPERATIVE_NAME = gql`
  query GetCooperativeName($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

export default props => {
  const { id } = useParams();
  const { data, loading, error } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  if (error) {
    console.log(error);
  }
  const title = data && `Società ${data.cooperative.businessName}`;

  return (
    <AppContent
      title={title || "..."}
      secondaryNavigator={<CooperativesDetailNavigator />}
      {...props}
    />
  );
};
