import React from "react";
import { Link } from "react-router-dom";
//
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Table from "components/enhancedTable";
import Button from '@material-ui/core/Button';
import CoreLink from '@material-ui/core/Link';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import { green, blue, lightGreen, lightBlue, grey } from '@material-ui/core/colors';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import GridOnIcon from '@material-ui/icons/GridOnOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import LaunchIcon from '@material-ui/icons/Launch';
import BookIcon from '@material-ui/icons/Book';

import UpdateCustomerDialog from "containers/updateCustomerDialog";

export const GET_ALL_CUSTOMERS = gql`
  query GetCustomers {
    customers {
      id
      businessName
      status
      createdAt
      homeFolder {
        url
      }     
    }
  }
`;

const etl = data => {
  return data.customers.map((customer, index) => [
    customer.businessName,
    <Grid container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={0}>
      <Grid item xs>
        <RenderStatus status={customer.status} />
      </Grid>
    </Grid>,
    new Date(customer.createdAt).toLocaleDateString(),
    <Grid container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={1}>
      <Grid item xs>
        {customer.homeFolder &&
          <Tooltip title={'Cartella documenti'} placement="top">
            <CoreLink href={customer.homeFolder.url} rel="noopener noreferrer" target="_blank">
              <IconButton
                size="small"
                //color="primary"      
                aria-label="add">
                <FolderIcon />
              </IconButton>
            </CoreLink>
          </Tooltip>}
      </Grid>
    </Grid>,
    <Grid container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={1}>
      <Grid item xs>
        {!customer.homeFolder && <UpdateCustomerDialog customer={customer} />}
      </Grid>
    </Grid>
  ]);
};

export default function () {
  const { data, loading, error } = useQuery(GET_ALL_CUSTOMERS, {
    pollInterval: 3000
  });

  if (loading) return <p>Caricamento...</p>;

  if (!data && error) return <p>ERROR: {error.message}</p>;

  return data && <Table headers={["Nome", "Stato", "Creato il", "Documenti", "Azioni"]}
    rows={etl(data)}
    sort='asc' />;
}

const RenderStatus = ({ status }) => {
  if (status === 'ACTIVE') return (<Tooltip title={status} placement="top"><CheckCircleOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  if (status && status.endsWith('ING')) return (<Tooltip title={status} placement="top"><CircularProgress disableShrink size={20} /></Tooltip>)
  if (status === 'ERROR') return (<Tooltip title={status} placement="top"><ErrorOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  return status;
}