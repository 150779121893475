import React, { Fragment } from "react";
//
import CreateCooperativeForm from "containers/createCooperativeForm";

export default function() {
  return (
    <Fragment>
      <CreateCooperativeForm />
    </Fragment>
  );
}
