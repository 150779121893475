import React, { Fragment } from "react";
import CustomersTable from "containers/customersTable";
//import CreateCooperativeButtonLink from "containers/createCooperativeButtonLink";
import CreateCustomerDialog from "containers/createCustomerDialog";

export default function() {
  return (
    <Fragment>
      <CustomersTable />
      <CreateCustomerDialog />
    </Fragment>
  );
}
