import React from "react";
import ReactDOM from "react-dom";
// Apollo
import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { ApolloProvider } from "@apollo/react-hooks";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { gql } from "apollo-boost";
// Auth0
import createAuth0Client from "@auth0/auth0-spa-js";
import config from "./config.json";
// UI
import App from "./app";

const cache = new InMemoryCache();

export const typeDefs = gql`
  extend type Query {
    waitingForAuthentication: Boolean!
    isAuthenticated: Boolean!
    name: String
    picture: String
  }

  extend type Mutation {
    loginWithRedirect(path: String!): Boolean
  }
`;

let oauthClient;

const httpLink = createHttpLink({
  //uri: 'https://plqpw42y57.execute-api.us-west-2.amazonaws.com/dev/graphql'  // 'http://localhost:4000/graphql'
  uri: `${process.env.REACT_APP_GRAPHQL_API_ENDPOINT}`
});

const authLink = setContext((_, { headers }) => {
  // Così legge direttamente dalla cache e non viene mai persistito nel browser..
  // in alternativa si può usare il local storage
  const token = cache.data.data.ROOT_QUERY && cache.data.data.ROOT_QUERY.email;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  typeDefs,
  resolvers: {
    Mutation: {
      loginWithRedirect: async (obj, { path }, context, info) => {
        try {
          await oauthClient.loginWithRedirect({
            appState: { targetUrl: path }
          });          
          return true;
        } catch (err) {
          console.log(err);
          return false;
        }
      },
      logout: async (obj, variables, context, info) => {
        await oauthClient.logout({
          returnTo: window.location.origin
        });
      }
    }
  }
});

const data = {
  waitingForAuthentication: true,
  isAuthenticated: false,
  name: "",
  email: "",
  picture: ""
};

cache.writeData({
  data
});

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const initAuth0 = async () => {

  if (process.env.REACT_APP_AUTH_AS) {
    data.email = process.env.REACT_APP_AUTH_AS //"test.consorzi@gmail.com"
    data.isAuthenticated = true;
    data.waitingForAuthentication = false;          
    client.writeData({ data });

    return;
  }  

  oauthClient = await createAuth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN || config.domain,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || config.clientId,
    redirect_uri: window.location.origin
  });


  if (window.location.search.includes("code=")) {
    const { appState } = await oauthClient.handleRedirectCallback();
    onRedirectCallback(appState);
  }

  const isAuthenticated = await oauthClient.isAuthenticated();

  /*
  if (isAuthenticated) {
    const { name, email, picture } = await oauthClient.getUser();

    data.isAuthenticated = isAuthenticated;
    data.name = name;
    data.email = email;
    data.picture = picture;
  }

  data.waitingForAuthentication = false;
  client.writeData({ data });
  */

  if (isAuthenticated) {    
    const { name, email, picture } = await oauthClient.getUser();

    console.log( window.location )

    //data.isAuthenticated = isAuthenticated;
    data.name = name;
    data.email = email;
    data.picture = picture;

    client.writeData({ data });

    try {
      const response = await client.query({
        query: gql`
          query me {
            me {
              type                      
              userName
              lastName
              firstName 
            }
          }
      `});
      data.isAuthenticated = (response.data.me.type.toLowerCase().includes('internal'));
    } catch (e) {
      console.log(e)
      data.isAuthenticated = false;
    } finally {
      data.waitingForAuthentication = false;          
      client.writeData({ data });
    }
    return
  }

  data.waitingForAuthentication = false;
  client.writeData({ data });
};

initAuth0();

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);
