import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStyles } from "./styles";

export default function() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress disableShrink />
    </div>
  );
}
