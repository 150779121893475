import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import DialogForm from "components/dialogForm";
import FormField from "components/formField";

export const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

export const CREATE_CONTRACT_REQUESTS = gql`
  mutation CreateContractRequests($input: CreateContractRequestsInput!) {
    createContractRequests(
      input: $input
    ) {
      code   
      success
      message       
    }
  }  
`;

export default function() {
  const { id } = useParams();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const [
    createContractRequests,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(CREATE_CONTRACT_REQUESTS);

  const { register, control, handleSubmit, formState, errors } = useForm({
    //mode: "onChange"
  });

  const onSubmit = data => {
    //const { code, agreementDate, startDate } = data;
    const { title } = data;

    const variables = {
      input: {
        cooperative: queryData.cooperative.businessName,
        title
        /*type: code,
        template: "???",
        agreementDate,
        startDate*/
      }
    };

    createContractRequests({
      variables
    });
  };

  if (queryError) return <p>ERROR: {queryError.message}</p>;

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={mutationLoading}
        message={"Creazione delle assunzioni avviata con successo."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Si è verificato un errore nella creazione delle assunzioni."}
      /> 
      <DialogForm
        id="create-contract-requests"
        title={
          queryData &&
          `Creazione Assunzioni ${queryData.cooperative.businessName}`
        }
        subTitle={queryData && `La seguente procedura avvierà la creazione delle assunzioni per la società ${queryData.cooperative.businessName}.`}
        buttonTitle='Crea assunzioni'
        formState={formState}
        onSubmit={handleSubmit(onSubmit)}
      >
          {/*<FormField
              id="title"
              name="title"
              label="Titolo"
              type="text"
              //inputRef={register({ required: "Inserisci il codice." })}
              control={control}
              error={errors.password}
            />                   
          <FormField
            id="stip"
            name="agreementDate"
            label="Data stipula"
            type="date"
            inputRef={register({ required: "Inserisci data di stipula." })}
            error={errors.password}
          />
          <FormField
            id="start"
            name="startDate"
            label="Data inizio"
            type="date"
            inputRef={register({ required: "Inserisci data di inizio." })}
            error={errors.password}
          /> */}
          <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={8}>
              { true && <FormField
                id="title"
                name="title"
                label="Titolo"
                type="text"
                //inputRef={register({ required: "Inserisci il codice." })}
                control={control}
                error={errors.password}
              />}
            </Grid>
          </Grid>
      </DialogForm>
    </Fragment>
  );
}
