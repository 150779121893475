import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles";

export default function({ title }) {
  const classes = useStyles();

  return (
    <AppBar
      component="div"
      className={classes.secondaryBar}
      color="primary"
      position="static"
      elevation={0}
    >
      <Toolbar>
        <Typography color="inherit" variant="h5" component="h1">
          {title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
