import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Grid from '@material-ui/core/Grid';
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Collapse from '@material-ui/core/Collapse';
import DialogForm from "components/buttonDialogForm";
import FormField from "components/formField";
import CloudOffOutlinedIcon from '@material-ui/icons/CloudOffOutlined';

import moment from "moment";
import "moment/locale/it";
moment.locale('it')

export const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

export const UNSHARE_CONTRACT_REQUESTS = gql`
  mutation UnshareContractRequests($input: UnshareContractRequestsInput!) {
    unshareContractRequests(
      input: $input
    ) {
      code    
    }
  }
`;

function usersOptions(sharedContractRequests) {   
  return removeDuplicates( sharedContractRequests.map( (sharedContractRequest) => { 
    return {
      label: sharedContractRequest.userName, 
      value: sharedContractRequest.userName};
  } ), 'label');
}

function userGroupsOptions(sharedContractRequests) {   
  return removeDuplicates( sharedContractRequests.map( (sharedContractRequest) => { 
    return {
      label: sharedContractRequest.groupName, 
      value: sharedContractRequest.groupName};
  } ), 'label');
}

function removeDuplicates(values, key) {
  return [...new Map( values.map(item => [item[key], item]) ).values()]
}

export default function({ contractRequests }) {
  const { id } = useParams();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const [
    unshareContractRequests,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(UNSHARE_CONTRACT_REQUESTS);

  const { register, control, watch, handleSubmit, formState, errors } = useForm({
    //mode: "onChange"
  });

  const onSubmit = data => {   
  
    const {  
      permittedUsersRead, 
      permittedGroupsRead,
      resetMode } = data;

    const variables = {
      input: {
        id: contractRequests.id,
        permittedUsersRead, 
        permittedGroupsRead,
        resetMode
      }
    };    

    unshareContractRequests({
      variables
    });
  };

  if (queryError) return <p>ERROR: {queryError.message}</p>;

  let resetMode = watch("resetMode", false);

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={mutationLoading}
        message={"Annullamento della condivisione delle assunzioni avviata con successo."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Si è verificato un errore nell'annullamento della condivisione delle assunzioni"}
      />
      <DialogForm
        id="share-contract-requests"
        title={
          queryData &&
          `Annullamento Condivisione Assunzioni ${queryData.cooperative.businessName}`
        }
        subTitle={queryData && `La seguente procedura avvierà l'annullamento della condivisione delle assunzioni per la società ${queryData.cooperative.businessName}.`}
        buttonTitle='Decondividi assunzioni'
        formState={formState}
        icon={CloudOffOutlinedIcon}
        onSubmit={handleSubmit(onSubmit)}
      >
          <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={4}>
              { true && <FormField
                id="resetMode"
                name="resetMode"
                label="resetMode"
                type="switch"
                inputRef={register()}
                control={control}
                error={errors.password}
                defaultValue={resetMode || false}
              /> }
            </Grid>            
          </Grid>

          {<Collapse in={!resetMode}><Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={1}>
              { true && <VisibilityIcon /> }
            </Grid>          
            <Grid item xs={11}>
              { true && <Typography>Abilita utenti e/o gruppi alla consultazione</Typography> }
            </Grid>          
            <Grid item xs={12}>
              { true && <FormField
                id="permittedUsersRead"
                name="permittedUsersRead"
                label="Utenti abilitati alla consultazione"
                type="multi-select"
                //inputRef={register()}
                control={control}
                error={errors.password}
                options={usersOptions(contractRequests.sharedContractRequests ? contractRequests.sharedContractRequests : [])}
                defaultValue={
                  usersOptions(contractRequests.sharedContractRequests ? contractRequests.sharedContractRequests : []).map((option) => option.value)
                }
              /> }
            </Grid>
            <Grid item xs={12}>
              { true && <FormField
                id="permittedGroupsRead"
                name="permittedGroupsRead"
                label="Gruppi abilitati alla consultazione"
                type="multi-select"
                //inputRef={register()}
                control={control}
                error={errors.password}
                options={userGroupsOptions(contractRequests.sharedContractRequests ? contractRequests.sharedContractRequests : [])}
                defaultValue={
                  userGroupsOptions(contractRequests.sharedContractRequests ? contractRequests.sharedContractRequests : []).map((option) => option.value)
                }
              /> }
            </Grid>            
          </Grid></Collapse>}                 
              
      </DialogForm>
    </Fragment>
  );
}
