import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';

let theme = createMuiTheme({
  palette: {
    /*primary: {
      light: "#3E5C76",
      main: "#1D2D44",
      dark: "#0D1321"
    }*/
    primary: blue,
    secondary: orange,
    //type: 'dark'  
  },
  typography: {
  //   h5: {
  //     fontWeight: 500,
  //     fontSize: 26,
  //     letterSpacing: 0.5
  //   }
    fontSize: 12
  },
  // shape: {
  //   borderRadius: 8
  // },
  // props: {
  //   MuiTab: {
  //     disableRipple: true
  //   }
  // },
  // mixins: {
  //   toolbar: {
  //     minHeight: 48
  //   }
  // }
});

// theme = {
//   ...theme,
//   overrides: {
//     MuiDrawer: {
//       paper: {
//         backgroundColor: "#e8eaf6"
//       }
//     },
//     MuiButton: {
//       label: {
//         textTransform: "none"
//       },
//       contained: {
//         boxShadow: "none",
//         "&:active": {
//           boxShadow: "none"
//         }
//       }
//     },
//     MuiTabs: {
//       root: {
//         marginLeft: theme.spacing(1)
//       },
//       indicator: {
//         height: 3,
//         borderTopLeftRadius: 3,
//         borderTopRightRadius: 3,
//         backgroundColor: theme.palette.common.white
//       }
//     },
//     MuiTab: {
//       root: {
//         textTransform: "none",
//         margin: "0 16px",
//         minWidth: 0,
//         padding: 0,
//         [theme.breakpoints.up("md")]: {
//           padding: 0,
//           minWidth: 0
//         },
//         [theme.breakpoints.down("sm")]: {
//           margin: "0 5px"
//         }
//       }
//     },
//     MuiIconButton: {
//       root: {
//         padding: theme.spacing(1)
//       }
//     },
//     MuiTooltip: {
//       tooltip: {
//         borderRadius: 4
//       }
//     },
//     MuiDivider: {
//       root: {
//         backgroundColor: "#AFAB9E"
//       }
//     },
//     MuiListItemText: {
//       primary: {
//         fontWeight: theme.typography.fontWeightMedium
//       }
//     },
//     MuiListItemIcon: {
//       root: {
//         color: "inherit",
//         marginRight: 0,
//         "& svg": {
//           fontSize: 20
//         }
//       }
//     },
//     MuiAvatar: {
//       root: {
//         width: 32,
//         height: 32
//       }
//     }
//   }
// };

export default ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
