import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import DialogForm from "components/buttonDialogForm";
import FormField from "components/formField";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

export const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

export const CREATE_WORKER = gql`
  mutation CreateWorker($input: CreateWorkerInput!) {
    createWorker(
      input: $input
    ) {
      code
      success
      message
    }
  }  
`;

export default function ({ worker }) {
  const { id } = useParams();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const [
    createWorker,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(CREATE_WORKER);

  const { register, control, handleSubmit, formState, errors } = useForm({
    //mode: "onChange"
  });

  const onSubmit = () => {
    const {
      firstName,
      lastName,
      taxCode,
      email,
      cooperative   
    } = worker;

    const variables = {
      input: {
        firstName,
        lastName,
        taxCode,
        email,
        cooperative   
      }
    };

    createWorker({
      variables
    });
  };

  if (queryError) return <p>ERROR: {queryError.message}</p>;

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={mutationLoading}
        message={"Creazione di un lavoratore avviata con successo."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Si è verificato un errore nella creazione di un lavoratore."}
      /> 
      <DialogForm
        id="create-worker"
        title={
          queryData &&
          `Creazione Lavoratore ${queryData.cooperative.businessName}`
        }
        subTitle={queryData && `La seguente procedura avvierà la creazione di un lavoratore per la società ${queryData.cooperative.businessName}.`}
        buttonTitle='Crea lavoratore'
        formState={formState}
        icon={CreateNewFolderIcon}
        onSubmit={handleSubmit(onSubmit)}
      >
          { false && <FormField
            id="contractSector"
            name="contractSector"
            label="Settore contratto"
            type="text"
            //inputRef={register({ required: "Inserisci il codice." })}
            control={control}
            error={errors.password}
          />}          
          { false && <FormField
            id="onlySummary"
            name="onlySummary"
            label="Solo sommario"
            type="switch"
            //inputRef={register()}
            control={control}
            error={errors.password}
          /> }           
      </DialogForm>
    </Fragment>
  );
}
