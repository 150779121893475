import React from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useStyles } from "./styles";

export default function Header({ categories }) {
  const classes = useStyles();

  return (
    <AppBar
      component="div"
      className={classes.secondaryBar}
      color="primary"
      position="static"
      elevation={0}
    >
      <Tabs
        value={categories.activeLocation}
        textColor="inherit"
        variant="scrollable"
        scrollButtons="on"
      >
        {categories.tabs.map(({ text, path }, index) => (
          <Tab
            textColor="inherit"
            label={text}
            value={path}
            component={Link}
            to={path}
            key={index}
          />
        ))}
      </Tabs>
    </AppBar>
  );
}
