import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import DialogForm from "components/dialogForm";
import FormField from "components/formField";

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      code
      success
      message
    }
  }
`;

export default function() {
  const { id } = useParams();

  const [
    createCustomer,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(CREATE_CUSTOMER);

  const { register, control, handleSubmit, formState, errors } = useForm({
    //mode: "onChange"
  });

  const onSubmit = data => {    
    const { businessName } = data;

    const variables = {
      input: {
        businessName
      }
    };    

    createCustomer({
      variables
    });
  };

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={mutationLoading}
        message={"Creazione del committente avviata con successo."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Si è verificato un errore nella creazione del committente"}
      />
      <DialogForm
        id="create-customer"
        title={
          `Creazione Nuovo Committente`
        }
        subTitle={`La seguente procedura avvierà la creazione di un nuovo committente.`}
        buttonTitle='Crea committente'
        formState={formState}
        onSubmit={handleSubmit(onSubmit)}
      >
          { true && <FormField
            id="businessName"
            name="businessName"
            label="Ragione sociale"
            type="text"
            //inputRef={register({ required: "Inserisci il codice." })}
            control={control}
            error={errors.password}
          />}             
      </DialogForm>
    </Fragment>
  );
}
