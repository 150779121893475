import React, { Fragment } from "react";
import CooperativesDetailJobDescriptionsTable from "containers/cooperativesDetailJobDescriptionsTable";
import CreateCooperativeJobDescriptionDialog from "containers/createCooperativeJobDescriptionDialog";
export default function() {
  return (
    <Fragment>
      <CooperativesDetailJobDescriptionsTable />
      <CreateCooperativeJobDescriptionDialog />
    </Fragment>
  );
}
