import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import DialogForm from "components/buttonDialogForm";
import FormField from "components/formField";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

export const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

export const NOTIFY_WORKER = gql`
  mutation NotifyWorker($input: NotifyWorkerInput!) {
    notifyWorker(
      input: $input
    ) {
      code
      success
      message
    }
  }  
`;

export default function ({ worker }) {
  const { id } = useParams();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const [
    notifyWorker,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(NOTIFY_WORKER);

  const { register, control, handleSubmit, formState, errors } = useForm({
    //mode: "onChange"
  });

  const onSubmit = () => {
    const {
      taxCode   
    } = worker;

    const variables = {
      input: {
        taxCode   
      }
    };

    notifyWorker({
      variables
    });
  };

  if (queryError) return <p>ERROR: {queryError.message}</p>;

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={mutationLoading}
        message={"Invio della notifica al lavoratore avviata con successo."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Si è verificato un errore nell'invio della notifica al lavoratore."}
      /> 
      <DialogForm
        id="notify-worker"
        title={
          queryData &&
          //`Notifica Lavoratore ${queryData.cooperative.businessName}`
          `Notifica Lavoratore ${worker.taxCode}`
        }
        //subTitle={queryData && `La seguente procedura avvierà l'invio di una notifica al lavoratore per la società ${queryData.cooperative.businessName}.`}
        subTitle={queryData && `La seguente procedura avvierà l'invio di una notifica al lavoratore ${worker.taxCode}.`}
        buttonTitle='Notifica lavoratore'
        formState={formState}
        icon={NotificationsNoneIcon}
        onSubmit={handleSubmit(onSubmit)}
      >
          { false && <FormField
            id="contractSector"
            name="contractSector"
            label="Settore contratto"
            type="text"
            //inputRef={register({ required: "Inserisci il codice." })}
            control={control}
            error={errors.password}
          />}          
          { false && <FormField
            id="onlySummary"
            name="onlySummary"
            label="Solo sommario"
            type="switch"
            //inputRef={register()}
            control={control}
            error={errors.password}
          /> }           
      </DialogForm>
    </Fragment>
  );
}
