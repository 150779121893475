import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles";

export default function({ children, formState, title, onSubmit }) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <Typography component="h4" variant="h6">
        {title}
      </Typography>
      <form className={classes.form} noValidate onSubmit={onSubmit}>
        <Grid container spacing={2}>
          {React.Children.map(children, (child, index) => (
            <Grid item xs={12}>
              {child}
            </Grid>
          ))}
        </Grid>
        <Button
          type="submit"
          color="primary"
          disabled={!formState.isValid || formState.isSubmitting}
        >
          Crea
        </Button>
      </form>
    </Paper>
  );
}
