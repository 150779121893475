import React from "react";
import { Link, useParams } from "react-router-dom";
//
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Table from "components/enhancedTable";
import Button from '@material-ui/core/Button';
import CoreLink from '@material-ui/core/Link';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import { green, blue, lightGreen, lightBlue, grey } from '@material-ui/core/colors';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import GridOnIcon from '@material-ui/icons/GridOnOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import LaunchIcon from '@material-ui/icons/Launch';
import BookIcon from '@material-ui/icons/Book';

export const GET_ALL_COOPERATIVES = gql`
  query GetCooperatives {
    cooperatives {
      id
      businessName
      consortium
      isCompany
      status
      createdAt
      homeFolder {
        url
      }
      contractsFile {
        url
      }
      contractsFileReading {
        url
      }            
    }
  }
`;

const etl = (data, group) => {
  //return data.cooperatives.map(({ id, businessName, consortium }, index) => [
  //return data.cooperatives.map((cooperative, index) => [
  const filtered_cooperatives = data.cooperatives.filter( (obj, idx, all) => {
    return !group ? !obj.consortium.toLowerCase().includes('archivio') : obj.consortium.toLowerCase().includes('archivio')
  } );
  return filtered_cooperatives.map((cooperative, index) => [
    cooperative.businessName,
    (cooperative.isCompany ? 'srl' : 'coop'),
    cooperative.consortium,
    <Grid container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={0}>
      <Grid item xs>
        <RenderStatus status={cooperative.status} />
      </Grid>
    </Grid>,
    new Date(cooperative.createdAt).toLocaleDateString(),
    <Grid container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={1}>
      <Grid item xs>
        {cooperative.contractsFile &&
          <Tooltip title={'Scrivi libro matricola'} placement="top">
            <CoreLink href={cooperative.contractsFile.url} rel="noopener noreferrer" target="_blank" underline="none">
              <Button
                variant="outlined"
                size="small"
                //startIcon={<BookIcon />}
                startIcon={<GridOnIcon />}
              >
                Scrivi
              </Button>              
            </CoreLink>
          </Tooltip>}
      </Grid>
      <Grid item xs>
        {cooperative.contractsFileReading &&
          <Tooltip title={'Leggi libro matricola'} placement="top">
            <CoreLink href={cooperative.contractsFileReading.url} rel="noopener noreferrer" target="_blank" underline="none">              
              <Button
                variant="outlined"
                size="small"
                startIcon={<GridOnIcon />}
              >
                Leggi
              </Button>              
            </CoreLink>
          </Tooltip>}         
      </Grid>
      <Grid item xs>
        {cooperative.homeFolder &&
          <Tooltip title={'Cartella documenti'} placement="top">
            <CoreLink href={cooperative.homeFolder.url} rel="noopener noreferrer" target="_blank">
              <IconButton
                size="small"
                //color="primary"      
                aria-label="add">
                <FolderIcon />
              </IconButton>
            </CoreLink>
          </Tooltip>}
      </Grid>      
    </Grid>,
    <Grid container
      direction="row"
      justify="flex-start"
      alignItems="center"
      spacing={0}>
      {false && <Grid item xs>
        <Button><Link to={`/cooperatives/${cooperative.id}/contracts`}>Dettaglio</Link></Button>
      </Grid>}
      <Grid item xs>
        {cooperative.contractsFileReading &&
          <Tooltip title={'Dettaglio'} placement="top">
            <Link to={`/cooperatives/${cooperative.id}/contracts`}>
              <IconButton
                size="small"
                //color="primary"      
                aria-label="add">
                <LaunchIcon />
              </IconButton>
            </Link>
          </Tooltip>}
      </Grid>
    </Grid>
  ]);
};

export default function () {
  const { group } = useParams();

  const { data, loading, error } = useQuery(GET_ALL_COOPERATIVES, {
    pollInterval: 3000
  });

  if (loading) return <p>Caricamento...</p>;

  if (!data && error) return <p>ERROR: {error.message}</p>;

  return data && <Table headers={["Nome", "Tipo", "Consorzio", "Stato", "Creato il", "Documenti", "Azioni"]}
    //rows={etl(data)}
    rows={etl(data, group)}
    sort='asc' />;
}

const RenderStatus = ({ status }) => {
  if (status === 'ACTIVE') return (<Tooltip title={status} placement="top"><CheckCircleOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  if (status && status.endsWith('ING')) return (<Tooltip title={status} placement="top"><CircularProgress disableShrink size={20} /></Tooltip>)  
  if (status === 'ERROR') return (<Tooltip title={status} placement="top"><ErrorOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  return status;
}