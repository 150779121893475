import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import DialogForm from "components/buttonDialogForm";
import FormField from "components/formField";
import ReceiptIcon from '@material-ui/icons/Receipt';

export const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

export const CREATE_RECEIPT = gql`
  mutation CreateReceipt($input: CreateReceiptInput!) {
    createReceipt(input: $input) {
      code
      success
      message
    }
  }
`;

export default function({invoice}) {
  const { id } = useParams();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const [
    createReceipt,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(CREATE_RECEIPT);

  const { register, handleSubmit, formState, errors } = useForm({
    //mode: "onChange"
  });

  const onSubmit = data => {
    //const { code, agreementDate, startDate } = data;
    const { } = data;

    const variables = {
      input: {
        //cooperative: queryData.cooperative.businessName
        invoiceId: invoice.id
      }
    };

    createReceipt({
      variables
    });
  };

  if (queryError) return <p>ERROR: {queryError.message}</p>;

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={mutationLoading}
        message={"Creazione degli incassi avviata con successo."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Si è verificato un errore nella creazione degli incassi."}
      />
      <DialogForm
        id="create-receipt"
        title={
          queryData &&
          `Creazione Incassi ${queryData.cooperative.businessName}`
        }
        subTitle={queryData && `La seguente procedura avvierà la creazione degli incassi per la società ${queryData.cooperative.businessName}, partendo dalle fatture ${invoice.title}.`}
        buttonTitle='Crea incassi'
        formState={formState}
        icon={ReceiptIcon}
        onSubmit={handleSubmit(onSubmit)}
      >
          {/* <FormField
            id="code"
            name="code"
            label="Codice"
            type="text"
            inputRef={register({ required: "Inserisci il codice." })}
            error={errors.password}
          />
          <FormField
            id="stip"
            name="agreementDate"
            label="Data stipula"
            type="date"
            inputRef={register({ required: "Inserisci data di stipula." })}
            error={errors.password}
          />
          <FormField
            id="start"
            name="startDate"
            label="Data inizio"
            type="date"
            inputRef={register({ required: "Inserisci data di inizio." })}
            error={errors.password}
          /> */}
      </DialogForm>
    </Fragment>
  );
}
