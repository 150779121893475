import React from "react";
import { useParams } from "react-router-dom";
//
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Table from "components/enhancedTable";
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import { green, blue, lightGreen, lightBlue, grey } from '@material-ui/core/colors';
import GridOnIcon from '@material-ui/icons/GridOnOutlined';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import FolderSharedIcon from '@material-ui/icons/FolderShared';

import ButtonMenu from "components/buttonMenu";
import CancelCooperativeUniqueEmployeeCertificationDialog from "containers/cancelCooperativeUniqueEmployeeCertificationDialog";
import DispatchCooperativeUniqueEmployeeCertificationDialog from "containers/dispatchCooperativeUniqueEmployeeCertificationDialog";

const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

const GET_ALL_COOPERATIVE_UNIQUE_EMPLOYEE_CERTIFICATIONS = gql`
  query GetAllUniqueEmployeeCertificationBundles($cooperative: String!) {
    uniqueEmployeeCertificationBundles(cooperative: $cooperative) {
      id
      title
      status      
      sheetFile {
        url
      }
      pdfFolder {
        url
      }         
      createdAt
      sharingStatus
      sharedStatus   
    }
  }
`;

const etl = data => {
  return data.uniqueEmployeeCertificationBundles.map(
    (uniqueEmployeeCertificationBundle, index) => [
      //new Date(attendance.referenceTime).toLocaleDateString(),
      uniqueEmployeeCertificationBundle.title,
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          <RenderStatus status={uniqueEmployeeCertificationBundle.status} />
        </Grid>
      </Grid>,     
      new Date(uniqueEmployeeCertificationBundle.createdAt).toLocaleDateString(), 
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          {true && uniqueEmployeeCertificationBundle.sheetFile &&
            <Tooltip title={'File CUD'} placement="top">
              <Link href={uniqueEmployeeCertificationBundle.sheetFile.url} rel="noopener noreferrer" target="_blank">
                <IconButton
                  size="small"
                  //color="primary"      
                  aria-label="add">
                  <GridOnIcon />
                </IconButton>
              </Link>
            </Tooltip>}
        </Grid>
        <Grid item xs>
          {uniqueEmployeeCertificationBundle.pdfFolder &&
            <Tooltip title={'Cartella pdf'} placement="top">
              <Link href={uniqueEmployeeCertificationBundle.pdfFolder.url} rel="noopener noreferrer" target="_blank">
                <IconButton
                  size="small"
                  //color="primary"      
                  aria-label="add">
                  <PictureAsPdfIcon />
                </IconButton>
              </Link>
            </Tooltip>}
        </Grid>         
      </Grid>,
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          {uniqueEmployeeCertificationBundle.sheetFile && uniqueEmployeeCertificationBundle.pdfFolder 
            && <DispatchCooperativeUniqueEmployeeCertificationDialog uniqueEmployeeCertificationBundle={uniqueEmployeeCertificationBundle}/>}
        </Grid>
        <Grid item xs>               
          {uniqueEmployeeCertificationBundle.sheetFile 
            && <CancelCooperativeUniqueEmployeeCertificationDialog uniqueEmployeeCertificationBundle={uniqueEmployeeCertificationBundle}/>}
        </Grid>
      </Grid>      
    ]
  );
};

export default function () {
  const { id } = useParams();

  const {
    data: cooperativeNameData,
    loading: cooperativeNameLoading,
    error: cooperativeNameError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const { data, loading, error } = useQuery(GET_ALL_COOPERATIVE_UNIQUE_EMPLOYEE_CERTIFICATIONS, {
    variables: {
      cooperative:
        cooperativeNameData && cooperativeNameData.cooperative.businessName
    },
    pollInterval: 1000
  });

  if (loading || cooperativeNameLoading) return <p>Caricamento...</p>;
  if (!data && (error || cooperativeNameError)) return <p>ERROR: {error.message}</p>;

  return (<Table headers={[
    //"Periodo riferimento", 
    "Titolo", 
    "Stato",
    "Creato il", 
    "Documenti",
    "Azioni"]} rows={ etl(data) } />);
}

const RenderStatus = ({ status }) => {
  if (status === 'PREPARED') return (<Tooltip title={status} placement="top"><CheckCircleOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  if (status === 'DISPATCHED') return (<Tooltip title={status} placement="top"><CheckCircleIcon style={{ color: grey[500] }} /></Tooltip>)    
  //if (status === 'PREPARED') return (<Tooltip title={status} placement="top"><DoneIcon style={{ color: grey[500] }} /></Tooltip>)
  //if (status === 'EXPORTED') return (<Tooltip title={status} placement="top"><DoneAllIcon style={{ color: grey[500] }} /></Tooltip>)
  if (status && status.endsWith('ING')) return (<Tooltip title={status} placement="top"><CircularProgress disableShrink size={20} /></Tooltip>)  
  if (status === 'ERROR') return (<Tooltip title={status} placement="top"><ErrorOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  return status;
}