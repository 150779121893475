import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Grid from '@material-ui/core/Grid';
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import CreateIcon from '@material-ui/icons/Create';
import Divider from '@material-ui/core/Divider';
import DialogForm from "components/buttonDialogForm";
import FormField from "components/formField";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined';

import moment from "moment";
import "moment/locale/it";
moment.locale('it')

export const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

export const SHARE_CONTRACT_REQUESTS = gql`
  mutation ShareContractRequests($input: ShareContractRequestsInput!) {
    shareContractRequests(
      input: $input
    ) {
      code    
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetUsers($type: String) {
    users(type: $type) {
      userName
    }
  }
`;

export const GET_ALL_USER_GROUPS = gql`
  query GetUserGroups($type: String) {
    userGroups(type: $type) {
      groupName
      title
    }
  }
`;

function usersOptions(users) {   
  return users.map( (user) => { 
    return {
      label: user.userName, 
      value: user.userName};
  } );
}

function userGroupsOptions(userGroups) {   
  return userGroups.map( (userGroup) => { 
    return {
      label: userGroup.title, 
      value: userGroup.groupName};
  } );
}

export default function({ contractRequests }) {
  const { id } = useParams();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const {
    data: queryUsersData,
    loading: queryUsersLoading,
    error: queryUsersError
  } = useQuery(GET_ALL_USERS, {
    variables: { type: 'external' }
  });

  const {
    data: queryUserGroupsData,
    loading: queryUserGroupsLoading,
    error: queryUserGroupsError
  } = useQuery(GET_ALL_USER_GROUPS, {
    variables: { type: 'external' }
  });

  const [
    shareContractRequests,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(SHARE_CONTRACT_REQUESTS);

  const { register, control, handleSubmit, formState, errors } = useForm({
    //mode: "onChange"
  });

  const onSubmit = data => {   
  
    const { 
      customerOrCollaborator,
      isCollaborator, 
      permittedUsers, 
      permittedUsersRead, 
      permittedGroups, 
      permittedGroupsRead } = data;

    const variables = {
      input: {
        id: contractRequests.id,
        customer: !isCollaborator ? customerOrCollaborator : undefined, 
        collaborator: isCollaborator ? customerOrCollaborator : undefined, 
        permittedUsers, 
        permittedUsersRead, 
        permittedGroups, 
        permittedGroupsRead
      }
    };    

    shareContractRequests({
      variables
    });
  };

  if (queryError) return <p>ERROR: {queryError.message}</p>;

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={mutationLoading}
        message={"Condivisione delle assunzioni avviata con successo."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Si è verificato un errore nella condivisione delle assunzioni"}
      />
      <DialogForm
        id="share-contract-requests"
        title={
          queryData &&
          `Condivisione Assunzioni ${queryData.cooperative.businessName}`
        }
        subTitle={queryData && `La seguente procedura avvierà la condivisione delle assunzioni per la società ${queryData.cooperative.businessName}.`}
        buttonTitle='Condividi assunzioni'
        formState={formState}
        icon={CloudOutlinedIcon}
        onSubmit={handleSubmit(onSubmit)}
      >

          <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={8}>
              { true && <FormField
                id="customerOrCollaborator"
                name="customerOrCollaborator"
                label="Nome Gruppo di Lavoro Committente o Collaboratore"
                type="text"
                //inputRef={register({ required: "Inserisci il codice." })}
                control={control}
                error={errors.password}
                defaultValue={`Gruppo Lavoro ${queryData.cooperative.businessName}`}
              />}
            </Grid>
            <Grid item xs={4}>
              { true && <FormField
                id="isCollaborator"
                name="isCollaborator"
                label="Collaboratore"
                type="switch"
                //inputRef={register()}
                control={control}
                error={errors.password}
              /> }
            </Grid>            
          </Grid>
          
          <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={1}>
              { true && <CreateIcon /> }
            </Grid>          
            <Grid item xs={11}>
              { true && <Typography>Abilita utenti e/o gruppi alla modifica</Typography> }
            </Grid>                               
            <Grid item xs={12}>
              { true && <FormField
                id="permittedUsers"
                name="permittedUsers"
                label="Utenti abilitati alla modifica"
                type="multi-select"
                //inputRef={register()}
                control={control}
                error={errors.password}
                options={usersOptions(queryUsersData ? queryUsersData.users : [])}
              /> }
            </Grid>            
            <Grid item xs={12}>
              { (queryUserGroupsData && queryUserGroupsData.userGroups && queryUserGroupsData.userGroups.length > 0) && <FormField
                id="permittedGroups"
                name="permittedGroups"
                label="Gruppi abilitati alla modifica"
                type="multi-select"
                //inputRef={register()}
                control={control}
                error={errors.password}
                options={userGroupsOptions(queryUserGroupsData ? queryUserGroupsData.userGroups : [])}
              /> }
            </Grid>            
          </Grid>   

          <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={1}>
              { true && <VisibilityIcon /> }
            </Grid>          
            <Grid item xs={11}>
              { true && <Typography>Abilita utenti e/o gruppi alla consultazione</Typography> }
            </Grid>          
            <Grid item xs={12}>
              { true && <FormField
                id="permittedUsersRead"
                name="permittedUsersRead"
                label="Utenti abilitati alla consultazione"
                type="multi-select"
                //inputRef={register()}
                control={control}
                error={errors.password}
                options={usersOptions(queryUsersData ? queryUsersData.users : [])}
              /> }
            </Grid>
            <Grid item xs={12}>
              { (queryUserGroupsData && queryUserGroupsData.userGroups && queryUserGroupsData.userGroups.length > 0) && <FormField
                id="permittedGroupsRead"
                name="permittedGroupsRead"
                label="Gruppi abilitati alla consultazione"
                type="multi-select"
                //inputRef={register()}
                control={control}
                error={errors.password}
                options={userGroupsOptions(queryUserGroupsData ? queryUserGroupsData.userGroups : [])}
              /> }
            </Grid>            
          </Grid>                 
              
      </DialogForm>
    </Fragment>
  );
}
