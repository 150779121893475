import React from "react";
import { useLocation } from "react-router-dom";
import DrawerNavigator from "components/drawerNavigator";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

const LOGOUT = gql`
  mutation logout {
    logout @client
  }
`;

const routes = [
  {
    path: "#",
    text: "Consorzi",
    icon: <span />
  },
  {
    path: "/cooperatives",
    text: "Società",
    icon: <span />
  },
  {
    path: "/customers",
    text: "Committenti",
    icon: <span />
  },
  {
    path: "#",
    text: "Lavoratori",
    icon: <span />
  }
];

export default function(props) {
  const { pathname } = useLocation();
  const [logout] = useMutation(LOGOUT);

  const categories = routes.map(route => {
    new RegExp(`^${pathname}`).test(route.path)
      ? (route.active = true)
      : (route.active = false);
    return route;
  });

  const userActions = [
    {
      action: logout,
      text: "Logout",
      icon: <ExitToAppIcon />
    }
  ];

  return (
    <DrawerNavigator categories={categories} actions={userActions} {...props} />
  );
}
