import React, { Fragment } from "react";
import CooperativesTable from "containers/cooperativesTable";
import CreateCooperativeButtonLink from "containers/createCooperativeButtonLink";
import CreateCooperativeDialog from "containers/createCooperativeDialog";

export default function() {
  return (
    <Fragment>
      <CooperativesTable />

      {false && <CreateCooperativeButtonLink />}
      <CreateCooperativeDialog />
    </Fragment>
  );
}