import React, { Fragment } from "react";
import CooperativesDetailUniqueEmployeeCertificationsTable from "containers/cooperativesDetailUniqueEmployeeCertificationsTable";
import CreateCooperativeUniqueEmployeeCertificationDialog from "containers/createCooperativeUniqueEmployeeCertificationDialog";
export default function() {
  return (
    <Fragment>
      <CooperativesDetailUniqueEmployeeCertificationsTable />
      <CreateCooperativeUniqueEmployeeCertificationDialog />
    </Fragment>
  );
}
