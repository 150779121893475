import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import MenuButton from "components/menuButton";
import ArrowBackButton from "components/arrowBackButton";

export default ({ onMenuButtonClick }) => {
  //const { isExact: showMenuButton } = useRouteMatch("(/|/cooperatives)");
  const { isExact: showMenuButton } = useRouteMatch("(/|/cooperatives|/customers)");
  const { goBack, length, push } = useHistory();

  return showMenuButton ? (
    <Hidden smUp>
      <MenuButton onClick={onMenuButtonClick} />
    </Hidden>
  ) : length > 2 ? (
    <ArrowBackButton text="Indietro" onClick={goBack} />
  ) : (
    <ArrowBackButton
      text="Home"
      onClick={() => {
        push("/");
      }}
    />
  );
};
