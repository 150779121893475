import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Grid from '@material-ui/core/Grid';
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import CreateIcon from '@material-ui/icons/Create';
import Divider from '@material-ui/core/Divider';
import DialogForm from "components/buttonDialogForm";
import FormField from "components/formField";
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import moment from "moment";
import "moment/locale/it";
moment.locale('it')

export const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

export const PROTECT_CONTRACT_REQUESTS = gql`
  mutation ProtectContractRequests($input: ProtectContractRequestsInput!) {
    protectContractRequests(
      input: $input
    ) {
      code    
    }
  }
`;

export default function({ contractRequests }) {
  const { id } = useParams();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const [
    protectContractRequests,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(PROTECT_CONTRACT_REQUESTS);

  const { register, control, handleSubmit, formState, errors } = useForm({
    //mode: "onChange"
  });

  const onSubmit = data => {   
  
    const { } = data;

    const variables = {
      input: {
        id: contractRequests.id
      }
    };    

    protectContractRequests({
      variables
    });

  };

  if (queryError) return <p>ERROR: {queryError.message}</p>;

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={mutationLoading}
        message={"Blocco delle assunzioni avviata con successo."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Si è verificato un errore nel blocco delle assunzioni"}
      />
      <DialogForm
        id="share-contract-requests"
        title={
          queryData &&
          `Blocco Assunzioni ${queryData.cooperative.businessName}`
        }
        subTitle={queryData && `La seguente procedura avvierà il blocco delle assunzioni per la società ${queryData.cooperative.businessName}.`}
        buttonTitle='Blocca assunzioni'
        formState={formState}
        icon={LockOutlinedIcon}
        onSubmit={handleSubmit(onSubmit)}
      >
          <Grid container item xs={12} spacing={2} alignItems="center">
            <Grid item xs={8}>
              { false && <FormField
                id="customerOrCollaborator"
                name="customerOrCollaborator"
                label="Nome Gruppo di Lavoro Committente o Collaboratore"
                type="text"
                //inputRef={register({ required: "Inserisci il codice." })}
                control={control}
                error={errors.password}
                defaultValue={`Gruppo Lavoro ${queryData.cooperative.businessName}`}
              />}
            </Grid>
            <Grid item xs={4}>
              { false && <FormField
                id="isCollaborator"
                name="isCollaborator"
                label="Collaboratore"
                type="switch"
                //inputRef={register()}
                control={control}
                error={errors.password}
              /> }
            </Grid>            
          </Grid>                               
      </DialogForm>
    </Fragment>
  );
}
