import React, { Fragment } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";

const USER_INFO = gql`
  query UserInfo {
    name @client
    picture @client
  }
`;

export default function() {
  const {
    loading,
    data: { name, picture }
  } = useQuery(USER_INFO);

  return loading ? null : (
    <Fragment>
      {name}
      <IconButton color="inherit">
        <Avatar src={picture} />
      </IconButton>
    </Fragment>
  );
}
