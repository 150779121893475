import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  categoryHeaderPrimary: {
//    color: "#0D1321"
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
//    color: "#0D1321",
//    "&:hover,&:focus": {
//      backgroundColor: "#748CAB"
//    }
  },
  itemCategory: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  firebase: {
    fontSize: 22
  },
//  itemActiveItem: {
//    color: "#114B5F"
//  },
  itemPrimary: {
    fontSize: "inherit"
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2)
  }
}));

export { useStyles };
