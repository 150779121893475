import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import Form from "components/form";
import FormField from "components/formField";

export const CREATE_CONTRACT_BUNDLE = gql`
  mutation CreateContractBundle($input: CreateContractBundleInput!) {
    createContractBundle(input: $input) {
      code
      success
      message
    }
  }
`;

export default function() {
  const [
    createContractBundle,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(CREATE_CONTRACT_BUNDLE);

  const { register, handleSubmit, formState, errors } = useForm({
    mode: "onChange"
  });

  const onSubmit = data => {
    const { code, agreementDate, startDate } = data;

    const variables = {
      input: {
        cooperative: "ciccio",
        type: code,
        template: "???",
        agreementDate,
        startDate
      }
    };

    createContractBundle({
      variables
    });
  };

  if (mutationData) return <Redirect to="/cooperatives" />;

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={mutationLoading}
        message={"In progress..."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Error"}
      />
      <Form
        title="Nuova società"
        formState={formState}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormField
          id="code"
          name="code"
          label="Codice"
          type="text"
          inputRef={register({ required: "Inserisci il codice." })}
          error={errors.password}
        />
        <FormField
          id="stip"
          name="agreementDate"
          label="Data stipula"
          type="date"
          inputRef={register({ required: "Inserisci data di stipula." })}
          error={errors.password}
        />
        <FormField
          id="start"
          name="startDate"
          label="Data inizio"
          type="date"
          inputRef={register({ required: "Inserisci data di inizio." })}
          error={errors.password}
        />
      </Form>
    </Fragment>
  );
}
