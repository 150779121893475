import React, { Fragment } from "react";
import CooperativesDetailPayPacketsTable from "containers/cooperativesDetailPayPacketsTable";
import CreateCooperativePayPacketDialog from "containers/createCooperativePayPacketDialog";
export default function() {
  return (
    <Fragment>
      <CooperativesDetailPayPacketsTable />
      <CreateCooperativePayPacketDialog />
    </Fragment>
  );
}
