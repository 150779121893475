import React from "react";
import AppContent from "templates/appContent";
import CustomersNavigator from "containers/customersNavigator";

export default props => (
  <AppContent
    title="Committenti"
    secondaryNavigator={<CustomersNavigator />}
    {...props}
  />
);
