import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  main: {
    flex: 1,
    padding: theme.spacing(0, 3),
    //background: "#eaeff1"
  }
}));

export { useStyles };
