import React from "react";
import { useParams } from "react-router-dom";
//
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Table from "components/enhancedTable";
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import { green, blue, lightGreen, lightBlue, grey } from '@material-ui/core/colors';
import GridOnIcon from '@material-ui/icons/GridOnOutlined';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import CreateCooperativeReceiptDialog from "containers/createCooperativeReceiptDialog";

const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

const GET_ALL_COOPERATIVE_INVOICES = gql`
  query GetAllInvoices($cooperative: String!) {
    invoices(cooperative: $cooperative) {
      id
      title
      status      
      sheetFile {
        url
      }
      createdAt    
    }
  }
`;

const etl = data => {
  return data.invoices.map(
    (invoice, index) => [
      invoice.title,
      //invoice.status,
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          <RenderStatus status={invoice.status} />
        </Grid>
      </Grid>,      
      new Date(invoice.createdAt).toLocaleDateString(),
      /*<ButtonGroup variant="text" size="small" aria-label="small contained button group">
        {invoice.sheetFile && <Button><Link href={invoice.sheetFile.url} rel="noopener noreferrer" target="_blank">File fatture</Link></Button>}
        {false && invoice.pdfFolder && <Button><Link href={invoice.pdfFolder.url} rel="noopener noreferrer" target="_blank">Cartella pdf</Link></Button>}
        
        {invoice.sheetFile && <CreateCooperativeReceiptDialog invoice={invoice}/>}
      </ButtonGroup>*/  
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          {true && invoice.sheetFile &&
            <Tooltip title={'File fatture'} placement="top">
              <Link href={invoice.sheetFile.url} rel="noopener noreferrer" target="_blank">
                <IconButton
                  size="small"
                  //color="primary"      
                  aria-label="add">
                  <GridOnIcon />
                </IconButton>
              </Link>
            </Tooltip>}
        </Grid>
      </Grid>,  
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          {invoice.sheetFile && <CreateCooperativeReceiptDialog invoice={invoice}/>}
        </Grid>
      </Grid>         
    ]
  );
};

export default function () {
  const { id } = useParams();

  const {
    data: cooperativeNameData,
    loading: cooperativeNameLoading,
    error: cooperativeNameError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const { data, loading, error } = useQuery(GET_ALL_COOPERATIVE_INVOICES, {
    variables: {
      cooperative:
        cooperativeNameData && cooperativeNameData.cooperative.businessName
    },
    pollInterval: 1000
  });

  if (loading || cooperativeNameLoading) return <p>Caricamento...</p>;
  if (!data && (error || cooperativeNameError)) return <p>ERROR: {error.message}</p>;

  return <Table headers={["Titolo", "Stato", "Creato il", "Documenti", "Azioni"]} rows={etl(data)} />;
}

const RenderStatus = ({ status }) => {
  if (status === 'PREPARED') return (<Tooltip title={status} placement="top"><CheckCircleOutlineIcon style={{ color: grey[500] }} /></Tooltip>)  
  if (status && status.endsWith('ING')) return (<Tooltip title={status} placement="top"><CircularProgress disableShrink size={20} /></Tooltip>)
  if (status === 'ERROR') return (<Tooltip title={status} placement="top"><ErrorOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  return status;
}