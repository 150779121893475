import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styles";
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
//import LinkIcon from '@material-ui/icons/Link';
//import FolderSharedIcon from '@material-ui/icons/FolderShared';

export default function({
  items,
  buttonTitle,
  icon: Icon
}) {
  //const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {!Icon && <Button
        size="small"
        //color="primary"
        aria-label="add"
        aria-controls="simple-menu" 
        aria-haspopup="true"
        onClick={handleClick}>{buttonTitle || 'Crea nuovo'}</Button>}

      {Icon && items && items.length > 1 && <div>
      <Tooltip title={buttonTitle || 'Crea nuovo'} placement="top">
        <IconButton
          size="small"
          //color="primary"      
          aria-label="add"
          aria-controls="simple-menu" 
          aria-haspopup="true"        
          onClick={handleClick}>
          <Icon />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        { items.map( (item) => 
          <Link href={item.value} rel="noopener noreferrer" target="_blank">
            <MenuItem onClick={handleClose}>              
              <ListItemIcon>
                <Icon fontSize="small" />
              </ListItemIcon>               
              <Typography align="left" variant="inherit">{item.title}</Typography>           
            </MenuItem>
          </Link>
        ) }
      </Menu></div>}

      {Icon && items && items.length === 1 && <div>
        <Tooltip title={buttonTitle || items[0].title} placement="top">
        <Link href={items[0].value} rel="noopener noreferrer" target="_blank">
          <IconButton
            size="small"
            //color="primary"      
            aria-label="add">
            <Icon />
          </IconButton>
        </Link>
        </Tooltip>        
      </div>}
    </div>
  );
}

/*
<Tooltip title={'File assunzioni'} placement="top">
<Link href={contractRequest.sheetFile.url} rel="noopener noreferrer" target="_blank">
  <IconButton
    size="small"
    //color="primary"      
    aria-label="add">
    <Icon />
  </IconButton>
</Link>
</Tooltip>
*/