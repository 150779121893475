import React from "react";
import { useParams } from "react-router-dom";
//
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Table from "components/enhancedTable";
import Button from '@material-ui/core/Button';
import CoreLink from '@material-ui/core/Link';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import { green, blue, lightGreen, lightBlue, grey } from '@material-ui/core/colors';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import GridOnIcon from '@material-ui/icons/GridOnOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import LaunchIcon from '@material-ui/icons/Launch';
import BookIcon from '@material-ui/icons/Book';

import CreateCooperativeWorkerDialog from "containers/createCooperativeWorkerDialog";
import NotifyCooperativeWorkerDialog from "containers/notifyCooperativeWorkerDialog";

const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

const GET_ALL_COOPERATIVE_WORKERS = gql`
  query GetAllWorkers($cooperative: String!) {
    workers(cooperative: $cooperative) {
      status
      firstName
      lastName
      taxCode
      email
      cooperative
      createdAt
      homeFolder {
        url
      }
    }
  }
`;

const etl = data => {
  return data.workers.map(
    (worker, index) => [
      worker.taxCode,
      worker.lastName,
      worker.firstName,
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          <RenderStatus status={worker.status} />
        </Grid>
      </Grid>,
      new Date(worker.createdAt).toLocaleDateString(),
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={1}>
        <Grid item xs>
          {worker.homeFolder &&
            <Tooltip title={'Cartella documenti'} placement="top">
              <CoreLink href={worker.homeFolder.url} rel="noopener noreferrer" target="_blank">
                <IconButton
                  size="small"
                  //color="primary"      
                  aria-label="add">
                  <FolderIcon />
                </IconButton>
              </CoreLink>
            </Tooltip>}
        </Grid>      
      </Grid>,
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={1}>
        <Grid item xs>
          {!worker.homeFolder && <CreateCooperativeWorkerDialog worker={worker}/>}
        </Grid>
        <Grid item xs>
          {worker.homeFolder && <NotifyCooperativeWorkerDialog worker={worker}/>}
        </Grid>        
      </Grid>     
          
    ]
  );
};

/*
<ButtonGroup variant="text" size="small" aria-label="small contained button group">
  {worker.homeFolder && <Button><Link href={worker.homeFolder.url} rel="noopener noreferrer" target="_blank">Cartella documenti</Link></Button>}
  {!worker.homeFolder && <CreateCooperativeWorkerDialog worker={worker}/>}        
</ButtonGroup>
*/

export default function () {
  const { id } = useParams();

  const {
    data: cooperativeNameData,
    loading: cooperativeNameLoading,
    error: cooperativeNameError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const { data, loading, error } = useQuery(GET_ALL_COOPERATIVE_WORKERS, {
    variables: {
      cooperative:
        cooperativeNameData && cooperativeNameData.cooperative.businessName
    },
    pollInterval: 1000
  });

  if (loading || cooperativeNameLoading) return <p>Caricamento...</p>;
  if (!data && (error || cooperativeNameError)) return <p>ERROR: {error.message}</p>;

  return <Table headers={["Codice Fiscale", "Cognome", "Nome", "Stato", "Creato il", "Documenti", "Azioni"]} 
          rows={etl(data)} 
          sort='asc' />;          
}

const RenderStatus = ({ status }) => {
  if (status === 'ACTIVE') return (<Tooltip title={status} placement="top"><CheckCircleOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  if (status && status.endsWith('ING')) return (<Tooltip title={status} placement="top"><CircularProgress disableShrink size={20} /></Tooltip>)  
  if (status === 'ERROR') return (<Tooltip title={status} placement="top"><ErrorOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  return status;
}
