import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import DialogForm from "components/buttonDialogForm";
import FormField from "components/formField";
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

export const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

export const CANCEL_PAYPACKET = gql`
  mutation CancelPayPacketBundle($input: CancelPayPacketBundleInput!) {
    cancelPayPacketBundle(
      input: $input
    ) {
      code
      success
      message
    }
  }  
`;

export default function ({ payPacketBundle }) {
  const { id } = useParams();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const [
    cancelPayPacketBundle,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(CANCEL_PAYPACKET);

  const { register, control, handleSubmit, formState, errors } = useForm({
    //mode: "onChange"
  });

  const onSubmit = data => {
    //const { contractSector, onlySummary } = data;

    const variables = {
      input: {
        id: payPacketBundle.id
      }
    };

    cancelPayPacketBundle({
      variables
    });
  };

  if (queryError) return <p>ERROR: {queryError.message}</p>;

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={mutationLoading}
        message={"Annullamento delle buste paga avviata con successo."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Si è verificato un errore nell'annullamento delle buste paga"}
      />
      <DialogForm
        id="cancel-paypacket"
        title={
          queryData &&
          `Annullamento Buste Paga ${queryData.cooperative.businessName}`
        }
        subTitle={queryData && `La seguente procedura avvierà l'annullamento delle buste paga per la società ${queryData.cooperative.businessName}, partendo dalle buste paga ${payPacketBundle.title}.`}
        buttonTitle='Annulla buste paga'
        formState={formState}
        icon={DeleteIcon}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* <FormField
            id="code"
            name="code"
            label="Codice"
            type="text"
            inputRef={register({ required: "Inserisci il codice." })}
            error={errors.password}
          />
          <FormField
            id="stip"
            name="agreementDate"
            label="Data stipula"
            type="date"
            inputRef={register({ required: "Inserisci data di stipula." })}
            error={errors.password}
          />
          <FormField
            id="start"
            name="startDate"
            label="Data inizio"
            type="date"
            inputRef={register({ required: "Inserisci data di inizio." })}
            error={errors.password}
          /> */}
          { false && <FormField
            id="contractSector"
            name="contractSector"
            label="Settore contratto"
            type="text"
            //inputRef={register({ required: "Inserisci il codice." })}
            control={control}
            error={errors.password}
          />}          
          { false && <FormField
            id="onlySummary"
            name="onlySummary"
            label="Solo sommario"
            type="switch"
            //inputRef={register()}
            control={control}
            error={errors.password}
          /> }           
      </DialogForm>
    </Fragment>
  );
}
