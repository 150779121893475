import React from "react";
import Router from "./router";
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "./theme";

export default function() {
  return (
    <ThemeProvider>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}
