import React from "react";
import { useParams } from "react-router-dom";
//
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Table from "components/enhancedTable";
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

import { green, blue, lightGreen, lightBlue, grey } from '@material-ui/core/colors';
import GridOnIcon from '@material-ui/icons/GridOnOutlined';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import FolderSharedIcon from '@material-ui/icons/FolderShared';

import ButtonMenu from "components/buttonMenu";
import CancelCooperativeAttendanceDialog from "containers/cancelCooperativeAttendanceDialog";
import ExportCooperativeAttendanceDialog from "containers/exportCooperativeAttendanceDialog";
import ShareCooperativeAttendanceDialog from "containers/shareCooperativeAttendanceDialog";
import UnshareCooperativeAttendanceDialog from "containers/unshareCooperativeAttendanceDialog";
import CreateCooperativeSalaryDialog from "containers/createCooperativeSalaryDialog";

const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

const GET_ALL_COOPERATIVE_ATTENDANCES = gql`
  query GetAllAttendances($cooperative: String!) {
    attendances(cooperative: $cooperative) {
      id
      title
      status      
      sheetFile {
        url
      }
      pdfFolder {
        url
      }         
      createdAt
      sharingStatus
      sharedStatus
      sharedAttendances {
        id
        status
        permissions
        groupType
        groupName
        userType
        userName
        sharedStatus
        sharedFolders {
          url
        }
      }   
    }
  }
`;

const etl = data => {
  return data.attendances.map(
    (attendance, index) => [
      //new Date(attendance.referenceTime).toLocaleDateString(),
      attendance.title,
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          <RenderStatus status={attendance.status} />
        </Grid>
      </Grid>,
      <Grid container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={0}>
        <Grid item xs>            
          <RenderSharingStatus sharingStatus={attendance.sharingStatus} sharedStatus={attendance.sharedStatus} />
        </Grid>
      </Grid>,      
      new Date(attendance.createdAt).toLocaleDateString(),
      /*<ButtonGroup variant="text" size="small" aria-label="small contained button group">
        {attendance.sheetFile && <Button><Link href={attendance.sheetFile.url} rel="noopener noreferrer" target="_blank">File presenze</Link></Button>}
        {attendance.pdfFolder && <Button><Link href={attendance.pdfFolder.url} rel="noopener noreferrer" target="_blank">Cartella pdf</Link></Button>}        
        
        {attendance.sheetFile && !attendance.pdfFolder && <ExportCooperativeAttendanceDialog attendance={attendance}/>}
        {attendance.sheetFile && <CreateCooperativeSalaryDialog attendance={attendance}/>}
        
        {attendance.sheetFile
          //&& (!attendance.sharingStatus || !(attendance.sharingStatus.toLowerCase() === 'shared'))
          && <ShareCooperativeAttendanceDialog attendance={attendance}/>}
        {attendance.sheetFile 
          //&& (attendance.sharingStatus && attendance.sharingStatus.toLowerCase() === 'shared')
          && <UnshareCooperativeAttendanceDialog attendance={attendance}/>}

        {attendance.sheetFile && <CancelCooperativeAttendanceDialog attendance={attendance}/>}
      </ButtonGroup>*/  
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          {true && attendance.sheetFile &&
            <Tooltip title={'File presenze'} placement="top">
              <Link href={attendance.sheetFile.url} rel="noopener noreferrer" target="_blank">
                <IconButton
                  size="small"
                  //color="primary"      
                  aria-label="add">
                  <GridOnIcon />
                </IconButton>
              </Link>
            </Tooltip>}
        </Grid>
        <Grid item xs>
          {attendance.pdfFolder &&
            <Tooltip title={'Cartella pdf'} placement="top">
              <Link href={attendance.pdfFolder.url} rel="noopener noreferrer" target="_blank">
                <IconButton
                  size="small"
                  //color="primary"      
                  aria-label="add">
                  <PictureAsPdfIcon />
                </IconButton>
              </Link>
            </Tooltip>}
        </Grid>  

        <Grid item xs>
          {true && attendance.sharingStatus &&
            <ButtonMenu buttonTitle={'Cartelle condivise'} icon={FolderSharedIcon} items={ sharedFolderLinks({sharedAttendances: attendance.sharedAttendances}) } />}
        </Grid>        

      </Grid>,
      <Grid container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={0}>
        <Grid item xs>
          {attendance.sheetFile && !attendance.pdfFolder 
            && <ExportCooperativeAttendanceDialog attendance={attendance}/>}
        </Grid>
        <Grid item xs>
          {attendance.sheetFile 
            && <CreateCooperativeSalaryDialog attendance={attendance}/>}        
        </Grid>
        <Grid item xs>
          {attendance.sheetFile          
            && <ShareCooperativeAttendanceDialog attendance={attendance}/>}        
        </Grid>
        <Grid item xs>
          {attendance.sheetFile 
            && attendance.sharingStatus
            && <UnshareCooperativeAttendanceDialog attendance={attendance}/>}        
        </Grid> 
        <Grid item xs>               
          {attendance.sheetFile 
            && <CancelCooperativeAttendanceDialog attendance={attendance}/>}
        </Grid>
      </Grid>      
    ]
  );
};

export default function () {
  const { id } = useParams();

  const {
    data: cooperativeNameData,
    loading: cooperativeNameLoading,
    error: cooperativeNameError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const { data, loading, error, refetch } = useQuery(GET_ALL_COOPERATIVE_ATTENDANCES, {
    variables: {
      cooperative:
        cooperativeNameData && cooperativeNameData.cooperative.businessName
    },
    pollInterval: 1000
  });

  if (loading || cooperativeNameLoading) return <p>Caricamento...</p>;
  if (!data && (error || cooperativeNameError)) return <>
    <p>Si è verificato un errore durante il caricamento, si prega di riprovare</p>
    <button onClick={refetch()}>Riprova</button>
  </>;

  return <Table headers={[
    //"Periodo riferimento", 
    "Titolo", 
    "Stato",
    "Condivisione", 
    "Creato il", 
    "Documenti",
    "Azioni"]} rows={etl(data)} />;
}

const RenderStatus = ({ status }) => {
  if (status === 'PREPARED') return (<Tooltip title={status} placement="top"><CheckCircleOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  if (status === 'EXPORTED') return (<Tooltip title={status} placement="top"><CheckCircleIcon style={{ color: grey[500] }} /></Tooltip>)    
  //if (status === 'PREPARED') return (<Tooltip title={status} placement="top"><DoneIcon style={{ color: grey[500] }} /></Tooltip>)
  //if (status === 'EXPORTED') return (<Tooltip title={status} placement="top"><DoneAllIcon style={{ color: grey[500] }} /></Tooltip>)
  if (status && status.endsWith('ING')) return (<Tooltip title={status} placement="top"><CircularProgress disableShrink size={20} /></Tooltip>)  
  if (status === 'ERROR') return (<Tooltip title={status} placement="top"><ErrorOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  return status;
}

const RenderSharingStatus = ({ sharingStatus, sharedStatus }) => {  
  if (sharingStatus === 'SHARED' && sharedStatus === 'COMPLETED') return ( <Tooltip title={sharedStatus} placement="top"><CloudDoneIcon style={{ color: grey[500] }} /></Tooltip> )
  if (sharingStatus === 'SHARED') return ( <Tooltip title={sharingStatus} placement="top"><CloudIcon style={{ color: grey[500] }} /></Tooltip> )
  if (sharingStatus && sharingStatus.endsWith('ING')) return (<Tooltip title={sharingStatus} placement="top"><CircularProgress disableShrink size={20} /></Tooltip>)
  if (sharingStatus === 'ERROR') return (<Tooltip title={sharingStatus} placement="top"><ErrorOutlineIcon style={{ color: grey[500] }} /></Tooltip>)
  return sharingStatus;
}

const sharedFolderLinks = ({ sharedAttendances }) => {  
  if (!sharedAttendances) return [];
  return [...new Map( sharedAttendances.map(item => [item.groupName, {
    title: item.groupName,
    value: item.sharedFolders.url
  }]) ).values()];
}