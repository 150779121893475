import React from "react";
import { useLocation } from "react-router-dom";
import BarNavigator from "components/barNavigator";

export default function(props) {
  const { pathname } = useLocation();

  const categories = {
    tabs: [
      {
        path: `/cooperatives`,
        text: "Tutte le società"
      },
      {
        path: `/cooperatives/archived`,
        text: "Società archiviate"
      }      
    ],
    activeLocation: pathname
  };

  return <BarNavigator categories={categories} {...props} />;
}
