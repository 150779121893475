import React from "react";
import AppContent from "templates/appContent";
import CooperativesNavigator from "containers/cooperativesNavigator";

export default props => (
  <AppContent
    title="Cooperative"
    secondaryNavigator={<CooperativesNavigator />}
    {...props}
  />
);
