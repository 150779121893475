import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
//
import { useForm } from "react-hook-form";
//
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
//
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import DialogForm from "components/dialogForm";
import FormField from "components/formField";

import moment from "moment";
import "moment/locale/it";
moment.locale('it')

export const GET_COOPERATIVE_NAME = gql`
  query GetCooperative($id: ID!) {
    cooperative(id: $id) {
      businessName
    }
  }
`;

export const CREATE_UNIQUE_EMPLOYEE_CERTIFICATION = gql`
  mutation CreateUniqueEmployeeCertificationBundle($input: CreateUniqueEmployeeCertificationBundleInput!) {
    createUniqueEmployeeCertificationBundle(input: $input) {
      code
      success
      message
    }
  }
`;

/* function referenceMonthYearOptions() {

  let now = moment();   
  let prev = moment().subtract(1, 'months');
  let next = moment().add(1, 'months');

  let options = [];   
  options.push({
    label: prev.format('MMMM YYYY'), value: {month: prev.month()+1, year: prev.year()}
  });  
  options.push({
    label: now.format('MMMM YYYY'), value: {month: now.month()+1, year: now.year()}
  });  
  options.push({
    label: next.format('MMMM YYYY'), value: {month: next.month()+1, year: next.year()}
  });   
  return options;
} */

function referenceMonthYearOptions() {

  let now = moment();
  let next = moment().add(1, 'months');

  let options = [];   
  for (let i = 5; i > 0; i--) {
    let prev = moment().subtract(i, 'months');
    options.push({
      label: prev.format('MMMM YYYY'), value: {month: prev.month()+1, year: prev.year()}
    });
  } 
  options.push({
    label: now.format('MMMM YYYY'), value: {month: now.month()+1, year: now.year()}
  });  
  options.push({
    label: next.format('MMMM YYYY'), value: {month: next.month()+1, year: next.year()}
  });   
  return options;
}

export default function() {
  const { id } = useParams();

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_COOPERATIVE_NAME, {
    variables: { id }
  });

  const [
    createUniqueEmployeeCertificationBundle,
    { data: mutationData, loading: mutationLoading, error: mutationError }
  ] = useMutation(CREATE_UNIQUE_EMPLOYEE_CERTIFICATION);

  const { register, control, handleSubmit, formState, errors } = useForm({
    //mode: "onChange"
  });

  const onSubmit = data => {    
    const { referenceMonthYear, onlySummary, split } = data;

    const variables = {
      input: {
        cooperative: queryData.cooperative.businessName,
        year: (referenceMonthYear ? referenceMonthYear.year : undefined),
        month: (referenceMonthYear ? referenceMonthYear.month : undefined) 
      }
    };    

    createUniqueEmployeeCertificationBundle({
      variables
    });
  };

  if (queryError) return <p>ERROR: {queryError.message}</p>;

  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={mutationLoading}
        message={"Creazione dei CUD avviata con successo."}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={Boolean(mutationError)}
        message={"Si è verificato un errore nella creazione dei CUD."}
      />
      <DialogForm
        id="create-uniqueemployeecertification"
        title={
          queryData &&
          `Creazione CUD ${queryData.cooperative.businessName}`
        }
        subTitle={queryData && `La seguente procedura avvierà la creazione dei CUD per la società ${queryData.cooperative.businessName}, saranno escluse tutte le matricole NON attive e/o con data di cessazione del contratto antecedente al primo giorno del mese di riferimento.`}
        buttonTitle='Crea CUD'
        formState={formState}
        onSubmit={handleSubmit(onSubmit)}
      >
          { false && <FormField
            id="code"
            name="code"
            label="Codice"
            type="text"
            //inputRef={register({ required: "Inserisci il codice." })}
            control={control}
            error={errors.password}
          />}
          { false && <FormField
            id="stip"
            name="agreementDate"
            label="Data stipula"
            type="date"
            inputRef={register({ required: "Inserisci data di stipula." })}
            error={errors.password}
          />}
          { true && <FormField
            id="referenceMonthYear"
            name="referenceMonthYear"
            label="Mese e anno di riferimento"
            type="select"
            //inputRef={register()}
            control={control}
            error={errors.password}
            options={referenceMonthYearOptions()}
          /> }
          { false && <FormField
            id="onlySummary"
            name="onlySummary"
            label="Solo sommario"
            type="switch"
            //inputRef={register()}
            control={control}
            error={errors.password}
          /> }  
          { false && <FormField
            id="split"
            name="split"
            label="Dividi per committenti"
            type="switch"
            //inputRef={register()}
            control={control}
            error={errors.password}
          /> }               
      </DialogForm>
    </Fragment>
  );
}
