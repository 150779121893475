import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import { useStyles } from "./styles";

export default function({ avatar, navigationButton }) {
  const classes = useStyles();

  return (
    <AppBar color="primary" position="sticky" elevation={0}>
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <Grid item>{navigationButton}</Grid>
          <Grid item xs />
          <Grid item>{avatar}</Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
