import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "./styles";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

export default function({
  id,
  children,
  title,
  subTitle,
  buttonTitle,
  formState,
  onSubmit,
  icon: Icon
}) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {!Icon && <Button
        size="small"
        //color="primary"
        aria-label="add"
        onClick={handleClickOpen}>{buttonTitle || 'Crea nuovo'}</Button>}

      {Icon && <Tooltip title={buttonTitle || 'Crea nuovo'} placement="top"><IconButton
        size="small"
        //color="primary"      
        aria-label="add"
        onClick={handleClickOpen}>
        <Icon />
      </IconButton></Tooltip>}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{subTitle}</DialogContentText>
          <form
            id={`${id}-form`}
            className={classes.form}
            noValidate
            onSubmit={onSubmit}
          >
            <Grid container spacing={2}>
              {React.Children.map(children, (child, index) => (
                <Grid item xs={12}>
                  {child}
                </Grid>
              ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Annulla
          </Button>
          <Button
            type="submit"
            form={`${id}-form`}
            onClick={handleClose}
            color="primary"
            disabled={React.Children.length > 0 && (!formState.isValid || formState.isSubmitting)}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
